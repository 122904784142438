.AboutSectionTitle{
    color: var(--accent);
    text-align: center;
    margin-top: 100px;
}

.aboutSection{
    background: rgb(57,62,70);
    background: linear-gradient(0deg, rgba(57,62,70,1) 0%, rgba(34,40,49,1) 100%);
}

.personal{
    margin-top: 100px;
    width: 800px;
    height: auto;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 5px;
    border-color: var(--2ndBG);
    border-style: solid;
    margin-right: 70px;
}

.textContainer p{
    width: 70%;
    font-size: 20px;
    line-height: 30px;
}



.AboutPage{
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: var(--2ndBG);
    color: var(--accent);
    padding: 20px 13px;
    border: 2px solid var(--accent);
    border-radius: 12px;
    text-decoration: none;
    font-size: 20px;
    transition: all 0.5s;
}

.AboutPage a{
    color: var(--accent);
}

.AboutPage a:hover{
    color: var(--text);
}

.AboutPage:hover {
    color: var(--text);
    background-color: var(--accent);
    font-size: 25px;
}

@media screen and (max-width: 768px) {
    .personal{
    margin-top: 100px;
    width: 390px;
    height: auto;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 5px;
    border-right: 0px;
    border-color: var(--2ndBG);
    border-style: solid;
    margin-right: 0px;
    margin-bottom: 40px;
    }

    .containerRow{
        flex-direction: column;
    }

    .textContainer p{
    width: 90%;
    font-size: 14px;

}
}