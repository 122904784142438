.TabButtonsAbout{
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 95%;
    justify-content: center;
}

button{
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    background-color: transparent;
    color: var(--accent);
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    margin-left: 10px;
    margin-bottom: 10px;
}

button.active2 {
    background-color: var(--2ndBG);
    color: var(--accent);
}

#tab-content-About {
    padding: 1rem;
    border-radius: 6px;
    background-color: var(--2ndBG);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    width: 88%;
    margin-bottom: 100px;
}