footer{
    background-color: var(--2ndBG);
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

p span{
    color: var(--accent);
    font-weight: bolder;
}

.SocialFooter{
    font-size: 20px;
    color: var(--text);
    margin-right: 30px;
    transition: all 0.5s;
    list-style: none;
}

.SocialFooter .justButton:hover{
    transform: scale(1.1);
    color: #00adb5;
}

@media screen and (max-width: 768px) {
    footer{
        background-color: var(--2ndBG);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}