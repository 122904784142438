:root {
  --BG: #222831;
  --2ndBG: #393E46;
  --accent: #00ADB5;
  --text: #EEEEEE;
}

body {
  font-family: 'Fira Code', monospace;
  background-color: var(--BG);
  color: var(--text);
  margin: 0px;
  padding: 0px;
}

.section{
  width: 100%;
  min-height: 100vh;
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center{
  display: flex;
  justify-content: center;
}

h2, h1, h3{
  color: var(--accent);
}